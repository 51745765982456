import { Fingerprint } from "@synerai/react-fingerprint";
import { PropsWithChildren } from "react";

import { ApiBaseUrl } from "@/util/app";

const FingerprintWrapper = ({ children }: PropsWithChildren) => {
  const fppPattern = `${ApiBaseUrl}/wbapi_public/fpp/v<version>/<apiKey>/loader_v<loaderVersion>.js`;
  return <Fingerprint fppPattern={fppPattern}>{children}</Fingerprint>;
};

export default FingerprintWrapper;
